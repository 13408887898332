.cookieAlert {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 999;
  width: 100%;
  padding: 10px 15%;
  background: #181818;
  text-align: center;

  p {
    color: #ffffff;
    line-height: 20px;
    font-size: 13px; margin: 0;

    button {
      background: linear-gradient(to bottom, #0088cc, #0044cc);
      color: #fff;
      font-size: 13px;
      border: none;
      height: 27px;
      width: 38px;
      border-radius: 3px;
      margin-left: 30px;
      line-height: 17px;
    }
  }

  .closeBtn {
    position: absolute;
    right: 9px;
    top: 1px;
    text-align: center;
    font-size: 15px;
    color: #ffffff;
    cursor: pointer;
  }
}

@media only screen and (max-width: 479px){
  .cookieAlert{padding: 10px 25px;}
  .cookieAlert p{ line-height: normal; font-size: 11px;}
}